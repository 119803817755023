@import '../css/shared';

.slider-a {
  overflow: hidden;

  .slider-button {
    box-shadow: $box-shadow !important;
    padding: 1rem;
    border-radius: 50%;
    background: $white;
  }

  .swiper-button-prev.swiper-button-small {left: 1rem;}
  .swiper-button-next.swiper-button-small {right: 1rem;}

  @include media-breakpoint-up(xl) {

    .slider-navigation {display: none;}

  }

  .swiper {
    overflow: visible;

    @include media-breakpoint-up(lg) {
      
      .message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        
        a {
          color: $white;
        }
        
      }

    }

    @include media-breakpoint-down(lg) {
      
      .message {
        
        a {
          color: $black;
        }
        
      }

    }
    
  }

}