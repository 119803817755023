@import '../css/shared';

.slider-b {
  overflow: hidden;

  .swiper {

    .slide {
      text-align: center;

      a.active {
        background: $white;
      }

    }

  }

  @include media-breakpoint-up(lg) {}

  @include media-breakpoint-down(xl) {

    .swiper {
      overflow: visible;
    }

    .slider-navigation {
      display: none !important;
    }

  }

}