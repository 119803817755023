@import '../css/shared';

.slider-button {

  svg {
    fill: #000000;
  }

  &.swiper-button-prev {left: 0;}
  &.swiper-button-next {right: 0;}

  &.swiper-button-prev.swiper-button-large {padding-right: 0;}
  &.swiper-button-next.swiper-button-large {padding-left: 0;}

  &.swiper-button-prev,
  &.swiper-button-next {
    margin: auto;
    transition: opacity 0.3s;
    top: 0;
    bottom: 0;

    &.swiper-button-large {
      width: 80px;
      height: 80px;
    }

    &.swiper-button-small {
      width: 54px;
      height: 54px;
    }

    &:after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }

  }

  @include media-breakpoint-down(xl) {
    
    &.swiper-button-prev,
    &.swiper-button-next {
      display: none;
    }

  }

}