@import 'css/shared';


footer {

  @include media-breakpoint-up(lg) {
    
    .accordion {
      .accordion-item {
        border-bottom: 0 !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    .container-xxl {padding: 0;}

    .footer-payment-icons {
      background: black;
    }
    .accordion {

      .accordion-item {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $white;
        border-radius: 0;
      }

      .accordion-header button {
        box-shadow: none;

        &:after {
          background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }

      }

      .row {
        margin: 0;

        & > div {
          padding: 0;
        }
      }
    }
  
  }

}