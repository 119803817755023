@import 'css/shared';

$fade-duraion: 200ms;

.mobile-header {
  background-color: $black;
  transition: background-color $fade-duraion;
  &.transparent {
    background-color: transparent;
    border-bottom-color: transparent !important;
  }
}

