.product-sticky-footer {
  bottom: -100%;
  transition: all 1s;

  &.show {
    bottom: 0;
  }

  svg {
    fill: white;
  }
}