.top-suggestions-box {
  .truncate-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &[data-lines='2'] {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }

  .product {
    grid-template-columns: auto 1fr auto;
  }
}
