@import '../css/shared';

.ambassador-page {

  @include media-breakpoint-up(lg) {

    .ambassador-details {
      margin-top: -180px;
    }

  }

  @include media-breakpoint-down(lg) {

    .ambassador-details {
      margin-top: -100px;
    }

    .image-ambassador-profile {
      text-align: center;
      
      img {
        max-width: 200px;
        border: 4px solid $white;
      }

    }

  }
  
}