.floating-action-button {
  width: 50px;
   
  a {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    padding: 5px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &:hover {opacity: 0.8;}

}