.product-assembler {
  .steps {
    $line-width: 2px;
    .step {
      .circle-wrapper {
        position: relative;
        width: 100%;
        &:before,
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          height: $line-width;
          top: 50%;
          transform: translateY(-50%);
          width: 50%;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
        .circle {
          margin: auto;
          position: relative;
          z-index: 2;
          border-width: $line-width !important;
          border-style: solid;
        }
      }
      //   &.behind {
      //   }
      &.current {
        .text {
          font-weight: bold;
        }
      }
      &.ahead {
        .circle {
          background-color: white !important;
        }
      }
      &.first {
        .circle-wrapper {
          &:before {
            display: none;
          }
        }
      }
      &.last {
        .circle-wrapper {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .desktop-summary {
    position: sticky;
    // overflow-y: auto;
  }

  .mobile-summary {
    position: sticky;
    z-index: 1021;
    bottom: 1rem;

    .summary-main-action {
      display: flex;
      flex-direction: column;
    }

    .summary-offcanvas {
      height: 0%;
      width: 100%;
      position: fixed;
      z-index: 100;
      bottom: 1rem;
      left: 0;
      overflow: hidden;
      padding: 0 1rem;
      transition: all 0.4s;

      &.active {
        height: calc(100% - 2rem);
      }

      .summary-container {
        height: 100%;
        overflow-y: auto;

        .summary-inner-container {
          min-height: 100%;
          background: #ffffff;

          .summary-header {
            position: sticky;
            top: 0;
            z-index: 100;
          }
        }
      }
    }
  }
}
