@import '../css/shared';

.search-page {

  .order-dropdown {
    button:after {display: none;}

    ul {
      top: -2px !important;
      padding: 0;
      border-color: $black;
      width: 100%;
    }

  }

  .pagination {
    
    .page-item {

      @include media-breakpoint-up(lg) {
        padding: 0 map-get($spacers, 2);
      }

      .page-link {
        border: 0;
        color: $black;
        background: transparent;
        border-radius: 0;
        padding: map-get($spacers, 2);
      }

      &.active {
        font-weight: 700;
        .page-link {
          border-bottom: 3px solid $primary;
        }
  
      }

    }

    .arrow-prev {
      a {
        font-size: 0;
        
        &:before {
          content: use-icon('e905');
          font-family: 'icomoon';
          font-size: $font-size-base;
        }

      }
    }

    .arrow-next {
      a {
        font-size: 0;
        
        &:before {
          content: use-icon('e902');
          font-family: 'icomoon';
          font-size: $font-size-base;
        }

      }
    }

  }

  // // Scrollbar customization - Start

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 2.75rem;
  }

  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 3rem; 
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track-piece {
    background: $gray-200;
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-300;
    border-radius: $border-radius;
  }

  // // Scrollbar customization - End

  .search-filters-container {
    position: sticky;

    overflow-y: scroll;
    overflow-x: hidden;

    .search-filters > ul {
      display: flex;
      flex-direction: column;

      & > li.filter-type-category {order: 2;}

      & > li:nth-child(2) {border-top: 0 !important;}

      & > li:last-child {
        border-bottom: 1px solid $border-color;
      }

    }

  }

  // #accordion-COLOR {

  //   ul {
  //     padding: 0 !important;
  //     display: flex;
  //     gap: 0.5rem;
  //     flex-wrap: wrap;
  //   }

  // }
  
  .search-filters {

    .main-filter {
      .btn,
      .form-check-label {
        font-size: $font-size-sm;
        text-transform: uppercase;
        color: $black !important;
        font-weight: 700;
      }
    }

  }

  .search-filters {

    .main-filter {
      
      .Icon {
        font-size: 12px !important;
      }

    }

    .form-check {
      font-weight: 700;

      .btn-check {
        position: initial;
        clip: initial;
        pointer-events: initial;
      }

    }
    
    .form-check-input {
      border-color: $black;
    }

    .filter-item-checkbox {

      .btn-check {
        margin-top: 7px;
      }

    }

  }
  
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}

}