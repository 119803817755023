@import 'css/shared';


.top-bar {

  .vr {
    height: 25px;
    align-self: auto;
  }

}