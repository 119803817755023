@import '../css/shared';

.builder-v1-page {
  .section-products {
    .product-tile {
      margin-bottom: 0 !important;

      .product-image {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 25px;
          overflow: hidden;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .grid-section {
    .cta-block.full-width>.btn {
      width: 100%;
    }
  }

  .products-slider-with-image {
    overflow-x: hidden;

    .slider-container {
      position: relative;

      @include media-breakpoint-down(xl) {
        padding-left: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        z-index: 200;
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 46px;
      }
    }

    .swiper {
      &.product-slider {
        .slide-action {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;

          >.wishlist {
            position: absolute;
            right: 10px;
            top: 0;
          }
        }
      }
    }

    .before-product-slider {
      position: relative;
      z-index: 100;

      img {
        max-width: 100%;
        width: 100%;
      }

      .before-product-slider-content {
        position: relative;
        z-index: 100;
        width: calc(100% - 60px);

        &:after {
          content: '';
          position: absolute;
          bottom: 5px;
          right: 10px;
          width: 20px;
          height: 25px;
          background: url(../images/logo-small.svg) no-repeat scroll center center transparent;
          background-size: contain;
        }

        @include media-breakpoint-down(lg) {
          width: calc(100% - 40px);
        }
      }
    }
  }
}