.Accordion {
  &.horizontal-layout {
    .accordion-item {

      border: 0;
      
      .accordion-collapse {
        display: block;
      }

      .accordion-button {
        padding: 0;
        pointer-events: none;

        &:after {
          display: none;
        }
      }

      .accordion-body {
        padding: 0;
      }
    }
  }
}