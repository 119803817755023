.circle-progress-bar {
  svg {
    width: 100%;
    height: 100%;

    .bar {
      transition: all 0.8s;
    }
  }
}
