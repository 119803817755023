@import 'css/shared';
 
.Breadcrumb {

  .breadcrumb {

    .breadcrumb-item {
      line-height: 12px;

      & + .breadcrumb-item:before {
        content: use-icon('e902');
        font-family: 'icomoon';
        font-size: 10px;
        color: $black;
        line-height: 14px;
      }

    }

  }

  @include media-breakpoint-down(lg) {

    overflow: hidden;

    ol {
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;

      li:before {
          float: none !important;
      }
      
    }

  }

}