@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'arboria', sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$headings-font-weight: 400;
$display-font-weight: 400;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.625;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.375;

$display-font-sizes: (
  1: 3.75rem,
);

$promo: #ff0505;
$black: #000;
$white: #fff;
$gray-100: #f6f6f6; // light
$gray-200: #e1e1e1;
$gray-300: #939599;
$gray-400: #4e4e4e;
$orange: #db6015;
$teal: #06524c;

$primary: $teal;
$light: $gray-200;
$lighter: $gray-100;
$secondary: $black;
$body-color: $black;

$custom-colors: (
  'white': $white,
  'promo': $promo,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'teal': $teal,
  'lighter': $gray-100,
);

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$border-color: $gray-400;
$enable-rounded: false;

$input-border-color: $gray-200;
$border-color: $gray-200;

$form-check-input-border: 1px solid $gray-200;
$form-check-input-checked-border-color: $gray-200;

$line-height-base: 1.625;

$btn-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

$input-btn-padding-x-sm: 1.5rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

// $utilities: map-merge(
//   $utilities,
//   (
//     "width": map-merge(
//       map-get($utilities, "width"),
//       (
//         values: map-merge(
//           map-get(map-get($utilities, "width"), "values"),
//           (10: 10%, 20: 20%, 30: 30%, 40: 40%, 60: 60%, 70: 70%, 80: 80%, 90: 90%),
//         ),
//       ),
//     ),
//   )
// );

.zindex-dropdown {
  z-index: $zindex-dropdown;
}

.zindex-sticky {
  z-index: $zindex-sticky;
}

.zindex-fixed {
  z-index: $zindex-fixed;
}

.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}

.zindex-modal {
  z-index: $zindex-modal;
}

.zindex-popover {
  z-index: $zindex-popover;
}

.zindex-tooltip {
  z-index: $zindex-tooltip;
}
