@import '../css/shared';

.home {

  .box-full-section {

    .message {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {

      .message {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        color: $white;
      }

    }

  }

  .box-section {

    .product-3d-viewer {
      background: url('../images/bg-product-3d.jpg') no-repeat scroll center center transparent;
      background-size: cover;
      @include media-breakpoint-up(lg) {
        iframe {
          height: 600px !important;
        }
      }
    }
  }

  .product-tab {
    overflow: hidden;
  }

  @include media-breakpoint-down(md) {
    

  }

}