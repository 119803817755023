@import 'css/shared';

.mobile-search-filter-offcanvas {

  .offcanvas-header-wrapper {
    background: $primary;

    .offcanvas-header {
      border: 0 !important;
    }

    .offcanvas-title {
      text-transform: none !important;
      color: $white;
    }

    .btn {
      color: $white;
    }

  }

}