@import 'css/shared';

.cart-offcanvas {
  .cart-packs {
    .cart-pack {
      & > .cart-sku:first-child > div {
        padding-top: 0 !important;
      }
      & > .cart-sku:last-child > div {
        padding-bottom: 0 !important;
      }

      .SeparatedList {
        .btn {
          //          font-weight: 300;
          color: $gray-400;
        }
      }

      .cart-sku-info {
        h6 {
          font-weight: 700;
        }
      }

      @include media-breakpoint-up(lg) {
        .cart-sku-action {
          padding: map-get($spacers, 3) 0 !important;

          .cart-sku-price {
            font-size: $h6-font-size;
          }

          & > div:first-child {
            & > .row > .col {
              width: 100%;
              display: flex;
              justify-content: flex-end;

              &:first-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .footer-message {
    padding: map-get($spacers, 3) 0 0;
    text-align: center;
  }
}
