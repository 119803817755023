@import 'css/shared';

.desktop-header {

    .desktop-header-menu {
        position: relative;
        z-index: 10;
    }

    .simple-search-form {

        input {
            background: $black;
            color: $white;
            order: 2;
            line-height: 38px;
        }
        
        .btn {
            color: $white;
            font-size: 25px;
            padding-right: 0 !important;
            order: 1;
        }

    }

    .sub-menu-dropdown {
        position: absolute;
        background: $black;
        z-index: 5;
        opacity: 0;
        transform: translateY(-15px);
        transition: opacity 0.8s ease, transform 0.5s ease;
    
        &.mounted {
          opacity: 1;
          transform: translateY(0px);
        }

        .main-column {
            min-height: 500px;
        }
        
    }

    /*// INIZIO - Aggiungo la riga bianca di separazione nel primo caso del menu //*/

    .row {
        position: relative;
    
        .MD_CMP_COMPENSATORI {

            &:before {
                content: '';
                height: calc(100% + 6rem);
                width: 1px;
                background: #FFFFFF;
                left: -1rem;
                right: 0;
                margin: auto;
                top: -3rem;
                position: absolute;
            }
    
        }
    
    } 

    /*// FINE - Aggiungo la riga bianca di separazione nel primo caso del menu //*/

}