@import '../css/shared';

.account-home-page {

  .btn {
    text-transform: uppercase;
  }

  .PageWithSubpageHeader {

    h1 {font-weight: 700;}
    
  }

  .box-home-account {
    h6 {
      text-transform: uppercase;
    }
  }

/*   .box-home-account {
    font-weight: 300;
  }  
 */
}