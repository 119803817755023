.Button {
  &.btn-blank {
    border: 0;
    padding: 0;
    // background: none;
    // color: inherit;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
