@import '../css/shared';

.account-page {

  .PageWithSubpageHeader {

    h1 {font-weight: 700;}
    
  }

  .subpage-title-container {
    h6 {
      text-transform: uppercase;
    }
  }

  .menu-account {

    .list-group a {font-weight: 600 !important;}
    .list-group {
      .list-group-item {
        &.active {
              color: $white !important;
              .Icon { 
                  color: $white !important;
              }
          }
      }
  }

  }

  .account-table {

    th {font-weight: 600 !important;}

  }

  .inner-box {

    .inner-box-title {
      font-weight: 400 !important;
      text-transform: uppercase;
    }

  }

  .btn-outline-primary {
    text-transform: uppercase;
  }

  .order-tracking-url {
    font-weight: 700;
  }

}