@import '../css/shared';

.slider-landing-product {

  @include media-breakpoint-up(xl) {


    .slider-button {
      box-shadow: $box-shadow !important;
      padding: 1rem;
      border-radius: 50%;
      background: $white;
    }

    .swiper-button-prev.swiper-button-small {left: -12px;}
    .swiper-button-next.swiper-button-small {right: -12px;}

  }

  @include media-breakpoint-down(xl) {
    
    .swiper {
      overflow: hidden;
    }

    .slider-navigation {display: none;}
    
    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }
    

  }


}