.product-sticky-footer {
  background-color: white;
  bottom: -100%;
  transition: all 1s;

  &.show {
    bottom: 0;
  }

  .image-wrapper {
    max-height: 60px;
    max-width: 60px;
    width: 100%;
  }

}