@import 'bootstrap-customization';
@import '~bootstrap/scss/bootstrap.scss';
@import 'mixins';

// OTHER CUSTOMIZATIONS AFTER IMPORT >>>>>

:root {
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }
}

@include generate-width-classes($grid-breakpoints);
