
.checkout-step {
  .card {
    border: 0;
  }
  .card-header,
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .step-number {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    line-height: 2rem;
    text-align: center;
    background-color: #FFFFFF;
  }
  .card-body {
    display: none;
    &.show {
      display: block;
    }
  }

  .step-disabled {color: #AAAAAA;}

}
