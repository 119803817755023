@import '../css/shared';

.slideshow {

  .slider-navigation,
  .slider-pagination {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    & > div {position: relative;}

    .swiper-pagination-bullet,
    .slider-button {
      pointer-events: initial;
    }
  }

  .slider-navigation {
    //max-width: calc(1920px - calc(map-get(container-max-widths, 'xxl') + 100));
    max-width:  map-get($container-max-widths , xxl) + 200;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 0 10px;

    .slider-button {

      svg {
        fill: $white;
        font-size: 70px !important;
      }
  
      &.swiper-button-prev,
      &.swiper-button-next {
        background: transparent !important;
        border: 0;
        
        &.swiper-button-large {
          width: 70px;
          height: 70px;
        }
      
      }
  
    }

  }

  .slider-pagination .pagination {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      background: transparent;
      border: 1px solid $white;
      margin: 0 10px;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $white;
      }

    }

  }

  @include media-breakpoint-up(lg) {

    .message {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .inner-container {
        padding: 0 80px;
      }

      .justify-content-center {
        text-align: center;
      }
      
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
    }

  }

  @include media-breakpoint-down(lg) {

    .slider-navigation {display: none;}

    .message {
      padding: map-get($spacers, 4) 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
    }

  }

}