.Drawers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  .Drawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    > .drawer-content {
      height: 100%;
      overflow: auto;
    }
    transition: transform 200ms;
    &.out {
      transform: translateX(100%);
    }
    &.in {
      transform: translateX(0%);
    }
    &.direct-child-active {
      transform: translateX(-10%);
      .in {
        transform: translateX(10%);
      }
      .out {
        transform: translateX(110%);
      }
    }

    // transform: translateX(100%);
    // &.child-active:not(.direct-child-active) {
    //   transform: translateX(0%);
    // }
    // &.active {
    //   transform: translateX(0%);
    // }
  }
}
