@import '../css/shared';

.service-page {

    .title {
        font-weight: 700;
    }
    .aside-menu-title {
        text-transform: uppercase;
        font-weight: 700;
        color: $white;
    }

    .subpage-title {
        font-size: $h5-font-size;
    }
    .aside-menu-item {
        a {
            font-size: 1rem;
        }
    }
    .faq-title {
        text-transform: uppercase;
        font-weight: 700;
    }

}