@import 'css/shared';

.mobile-navigation-offcanvas {

    .Drawers {
        
        .Drawer {
            background: $black;
            color: $white;

            .border-bottom {
                border-color: $gray-300 !important;
            }

        }

    }

    .simple-search-form {

        input {
            background: $black;
            color: $white;
        }
        
        .btn {
            color: $white;
        }

    }

    .footer {

        .drawer-locale-container {
            
            & > .d-grid > .btn {
                border: 0 !important;
            }

        }

    }

}