@import 'css/shared';

.ambassador-tile {

  .image-nationality {
    @include media-breakpoint-down(sm) {
      max-width: 12px;
    }
  }

}

.ambassador-list {

  .ambassador-profile {
    max-width: 100px;
    margin-top: -50px;
    
    @include media-breakpoint-up(sm) {
      max-width: 120px;
      margin-top: -60px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 180px;
      margin-top: -90px;
    }
    
  }

}

.swiper {

  .ambassador-profile {
    max-width: 110px;
    margin-top: -55px;
    
    @include media-breakpoint-up(sm) {
      max-width: 120px;
      margin-top: -60px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 180px;
      margin-top: -90px;
    }
    
  }

}
