@import '../css/shared';

.gift-card-page {

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-down(md) {

    .img-container {text-align: center;}
    
  }

}