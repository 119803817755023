.account-loyalty-page {
  .item-rule-name {
    @media (max-width: 575px) {
      min-width: 3.45rem;
    }
  }

  .point-bar {
    margin-top: 1.25rem;
    height: 1.25rem;
    border-radius: 3.75rem;
    position: relative;
    width: 100%;
    .bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      border-radius: 3.75rem;
      .point-wrapper {
        position: relative;
        width: 100%;
        height: 1.25rem;
        border-radius: 3.75rem;

        .point {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          right: 0.25rem;
          top: 0.25rem;
          z-index: 2;
        }
        .point-name {
          position: absolute;
          font-weight: bold;
          transform: translate(50%, 50%);
          right: 0.5rem;
          bottom: -1.25rem;
        }
      }
    }
  }
  .new-point-bar {
    margin-top: 1.25rem;
    height: 1.25rem;
    border-radius: 3.75rem;
    position: relative;
    width: 100%;
    .bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      border-radius: 3.75rem;
    }
    .step {
      position: absolute;
      top: 0;

      .bullet-point {
        width: 1rem;
        height: 1rem;
        border: 2px solid;
        border-radius: 50%;
        background-color: #fff;
        position: relative;
        transform: translateX(calc(-100% - 0.125rem));
        top: 0.125rem;
        z-index: 2;
      }
      .step-name {
        position: relative;
        font-weight: bold;
        margin-top: 0.625rem;
        transform: translateX(calc(-50% - 0.625rem));
        opacity: 0.5;
      }

      &.active-step {
        .bullet-point {
          border: 2px solid #fff;
        }
        .step-name {
          opacity: 1;
        }
      }

      &.zero-step {
        .bullet-point {
          transform: translateX(0.125rem);
        }
        .step-name {
          transform: none;
        }
      }
      &:last-of-type {
        .step-name {
          transform: translateX(-100%);
        }
      }
    }
  }
}
