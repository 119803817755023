@import 'css/shared';

.LoadingOverlay {
  position: fixed;
  z-index: 1100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
}
