@import 'css/shared';

.best-price {

    del {
        color: $secondary;
        font-weight: 300;
    }

    .text-promo {color: $promo}

}

.best-price-modal {

    .price-container {
        
        .price {
            font-size: $h5-font-size;
            font-weight: 700;
        }

    }

    .text-promo {color: $promo;}

    del {
        color: $secondary;
        font-weight: 300;
    }

}

.ProductTile .best-price {
    
    button svg {font-size: 17px !important;}
    
    @include media-breakpoint-up(md) {text-align: right;}
        
}

.ProductPage .best-price {

    button svg {font-size: 18px !important;}
    
}