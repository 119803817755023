@import 'css/shared';

.btn-check:disabled + .custom {
  .CustomRadio {
    opacity: 0.25;
    border: 2px solid #b7b7b7;
    cursor: not-allowed;
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px solid #b7b7b7;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }
}

.btn-check + .custom {
  .CustomRadio {
    /*outline: solid 2px $primary;*/
    /*box-shadow: 0 0 0 2px $primary;*/
    border: 2px solid transparent;
  }
}

.btn-check:checked + .custom {
  .CustomRadio {
    /*outline: solid 2px $primary;*/
    /*box-shadow: 0 0 0 2px $primary;*/
    border: 2px solid $primary;
  }
}

.btn-check.is-invalid + .custom {
  .CustomRadio {
    /*outline: solid 1px $red;*/
    /*box-shadow: 0 0 0 1px $red;*/
    border: 2px solid $red;
  }
}

.CustomRadio {
  height: 3.125rem;
  text-align: center;
  line-height: 3.125rem;
  width: auto;
  position: relative;
  cursor: pointer;

  &.rectangular-custom-radio {
    height: auto;
  }

  &.rounded-circle {
    width: 3.125rem;
    overflow: hidden;
  }
}
