@import 'css/shared';

input + label:hover {
  cursor: pointer;

  .custom-radio-check-image div {
    opacity: 0.7;
  }

}

input + label .custom-radio-check-image {
  border: 2px solid transparent;

  div {
    width: 25px;
    height: 25px;
    border: 2px solid transparent;
    background: #CCCCCC;
  }

}

// input:checked + label .custom-radio-check-image {
//   border: 2px solid $primary;

//   div {
//     border: 2px solid $white;
//   }
// }

.custom-radio-check-image,
.custom-radio-check-image div {
  border-radius: 50%;
  overflow: hidden;
}

.custom-radio-check-image div {

  img {
    width: 100% !important;
    height: 100% !important;
    display: none;
  }

}
