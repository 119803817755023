.Video {

  position: relative;

  * {font-size: 0;}

  .preview {
    width: 100%;
    height: 100%;
  }

  &.background {
    width: 100%;
    height: 100%;
    overflow: hidden;

    iframe,
    video {
      position: absolute;
      z-index: 90;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }

  }

  &.portrait {
    @media screen and (max-aspect-ratio: 9/16) {
      iframe, video {
        height: 100% !important;
        width: auto !important;
      }
    }
  }

  .overlay, .reload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;    
  }

  .overlay, .show-reload {
    background: rgb(0,0,0);
    background: -moz-radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.65) 100%);
    background: radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

    div {
      width: 80px;
      height: 80px;

      svg {
        width: 100% !important;
        height: auto !important;
      }
    }

  }

  .reload {
    visibility: hidden;
    opacity: 0;
    transition: all 0s;

    &.show-reload {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s;
    }

  }
  
}
