@import '../css/shared';

.checkout-page {
    .btn-sm, .btn-group-sm > .btn {
        padding: 0;
    }

  .checkout-step {
    
    .card {
      border-radius: 0;
      border-color: $white;
    
      .card-body.show {

        &.bg-white {
          border: 1px solid $gray-200;
          border-radius: $border-radius;
          overflow: hidden;
        }

      }

    }
    
  }

  .checkout-summary {
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    overflow: hidden;

    h5 {
      padding: 15px 0;
      font-weight: 700;
    }
  }
  
  @include media-breakpoint-down(lg) {}
  @include media-breakpoint-up(lg) {}

}