@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

// Temporary hide element - Start

// .b2x-env-production {
//   .top-bar .hstack > div:last-child {
//     width: 175px;
//   }

//   //.top-bar .hstack > div:first-child,
//   .top-bar .hstack > div:last-child > *,
//   .top-bar .hstack .vr,
//   .desktop-header .desktop-header-menu,
//   .desktop-header .header-search-col > div > div,
//   .desktop-header .heaader-cta-container,
//   .desktop-header .toggles .vr,
//   .desktop-header .toggles .btn-wishlist,
//   .desktop-header .toggles .btn-cart,
//   //.mobile-header .btn-menu,
//   .mobile-header .btn-wishlist,
//   .mobile-header .btn-cart,
//   .mobile-header .btn-search,
//   .mobile-navigation-offcanvas .search-container,
//   .mobile-navigation-offcanvas .menu-container,
//   .mobile-navigation-offcanvas .cta-container,
//   .product-tab,
//   .Section.ambassadors,
//   .footer-first-row .footer-icon-col .row,
//   .footer-second-row .accordion > .row > .col-lg:nth-child(2) {
//     display: none !important;
//   }
// }

// Temporary hide element - End

.pac-container {
  font-family: $font-family-sans-serif;
  padding: spacer(1) spacer(2);
  border: 1px solid $white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 $border-radius $border-radius;
  border-top: 0;

  * {
    font-size: $font-size-base;
  }

  .pac-item {
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
    line-height: $line-height-base;
    padding: spacer(1) 0;
    .pac-icon {
      width: 24px;
      height: auto;
      margin: 0;
      background: transparent;
      text-align: center;
      flex: 0 0 auto;

      &:after {
        font-family: 'icomoon';
        text-align: center;
        font-size: 24px;
        display: block;
        line-height: $line-height-base;
      }

      &.pac-icon-marker:after {
        content: use-icon('e911');
      }
    }

    .pac-item-query {
      padding-left: 0.5rem;
    }

    & > span:last-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // https://developers.google.com/maps/documentation/places/web-service/policies#logo
  &:after {
    display: none;
  }
}
