
.btn-configurator {
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: #333333;
  z-index: 100;
  border-radius: 10px;
}

.Configurator {

  font-family: 'Consolas';

  .settings {

    & > div:before {
      content: '';
      display: inline-block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      vertical-align: middle;
    }

    .enabled:before  {background: #198754;}
    .disabled:before {background: #ff0000;}
  }

}