@import '../css/shared';

.product-page {

  .product-secondary-info {

    .accordion {

      @include media-breakpoint-down(md) {

        & > .row {
          --bs-gutter-x: 0 !important;
        }

      }

    }

  }

  .product-gallery {

    // Scrollbar customization - Start

    ::-webkit-scrollbar-track-piece:start {
      margin-top: 2.75rem;
    }

    ::-webkit-scrollbar-track-piece:end {
      margin-bottom: 3rem; 
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track-piece {
      background: $gray-200;
      border-radius: $border-radius;
    }

    ::-webkit-scrollbar-thumb {
      background: $gray-300;
      border-radius: $border-radius;
    }

    // Scrollbar customization - End

    .swiper-button-next,
    .swiper-button-prev {
      color: $black;
    }

    .product-3d-viewer {
      height: 100%;

      iframe {
        height: 100% !important;
      }

      & > div { 
        height: 100% !important; 
      }
      
      .previewimageContainer {
        width: 100% !important;
        height: 100% !important;
      }

    }

    @include media-breakpoint-up(md) {

      .product-gallery-container {
        padding-right: 0.25rem;
        max-height: 100vh;
        overflow-y: scroll;
      }

      .swiper {
        overflow: visible;

        .swiper-wrapper {
          flex-wrap: wrap;
          transform: none !important;

          .swiper-slide {
            width: 50% !important;
          }
        
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }

      }
    
    }

  }

  .variant-slider-container {
    padding: 0 25px;
    
    .slider {
      overflow: hidden;

      .slider-button {
        width: 25px;

        svg {
          display: none;
        }

        &.swiper-button-prev:after {content: use-icon('e905');}
        &.swiper-button-next:after {content: use-icon('e902');}

        &.swiper-button-prev:after,
        &.swiper-button-next:after {
          font-family: 'icomoon';
          display: block;
          color: $black;
          font-size: 18px;
        }

      }

    }

  }

  .data-sheet {

    ul {
      list-style: none;
      padding: 0;
      column-count: 2;
      column-gap: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 5);

      li {
        margin-bottom: map-get($spacers, 2);
        line-height: $line-height-sm;

        @include media-breakpoint-up(md) {
          margin-bottom: map-get($spacers, 3);
        }

      }

      @include media-breakpoint-up(md) {
        margin: map-get($spacers, 5) 0;
        
      }

    }

  }

}

.product-sticky-footer {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.25) !important;

  .crossed-out-price {
    margin-right: map-get($spacers, 3);
  }

  .product-sticky-footer-desktop {
    background: $gray-100;

    .product-name {
      font-size: $h6-font-size;
    }

    .product-info {
      
      b {
        color: $primary;
      }

    }

    .btn {
      text-transform: uppercase;
      font-weight: 500;
    }

  }

}